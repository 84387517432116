<template>
  <el-card class="form-container" shadow="never" style="width: 98%">
    <el-form :model="disSettingModel" ref="disSettingForm" label-width="200px">
      <el-form-item label="返佣比例：" >
        <el-input v-model="disSettingModel.return_money" type="number" min="0" style="width: 30%">
          <template v-slot:append>%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="文件分佣比例：" >
        <el-input v-model="disSettingModel.file_return_money" type="number" min="0" style="width: 30%">
          <template v-slot:append>%</template>
        </el-input>
      </el-form-item>
<!--      <el-form-item label="开启/关闭：">
        <el-radio-group v-model="disSettingModel.switch_type">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="handleDialogConfirm('disSettingForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>

import {getDisSetting, updateDisSetting} from '@/api/disSetting';

const defaultDisSetting = {
  id: null,
  return_money: 0,
  file_return_money: 0,
  switch_type: 1,
};

export default {
  name: 'disSetting',
  components: {},
  data() {
    return {
      disSettingModel: Object.assign({}, defaultDisSetting),
    }
  },
  created() {

    this.getDetail();
  },
  methods: {
    getDetail(){
      let data =  {
        "dataId": 1,
      };
      getDisSetting(data).then(response=>{
            if (response.code === '0') {
              this.disSettingModel = response.data;
            }
      })
    },
    //提交操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            updateDisSetting(this.disSettingModel).then(res => {
              if (res.code === '0') {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                });
              }else {
                this.$message({
                  type: "error",
                  message: res.msg
                })
              }
            });

          });
        }
      });
    },

  }
}
</script>
<style scoped>

</style>
